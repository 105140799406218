import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const MAX_META_DESCRIPTION_LENGTH = 160
const ELLIPSIS = '...'
const BASE_URL = 'https://www.skylightframe.com'

function SEO({ description, lang, meta, title, path, imagePath }) {
  const { site, ogImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        ogImage: file(relativePath: { eq: "hero-image.webp" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `
  )

  let metaDescription = description || site.siteMetadata.description
  if (metaDescription.length > MAX_META_DESCRIPTION_LENGTH - ELLIPSIS.length) {
    metaDescription = metaDescription.substring(0, MAX_META_DESCRIPTION_LENGTH) + ELLIPSIS
  }

  const fullImageUrlFromPath = () => {
    if (!imagePath)
      return [BASE_URL, ogImage.childImageSharp.gatsbyImageData.images.fallback.src].join('')
    if (imagePath.startsWith('/')) return [BASE_URL, imagePath].join('')
    return imagePath
  }

  const metaTags = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:site_name`,
      content: title || site.siteMetadata.title,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(meta)
  if (path) {
    metaTags.push({
      property: `og:url`,
      content: [BASE_URL, path].join(''),
    })
  }
  metaTags.push({
    property: `og:image`,
    content: fullImageUrlFromPath(),
  })
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate="Skylight | %s"
      meta={metaTags}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  path: PropTypes.string,
  imagePath: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
