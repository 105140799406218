import React from 'react'

import { Hr, MediumText, SmallText } from 'src/utils/styles/global-styles'
import { Image, TabTitle } from './styles'

const MobileApp = () => (
  <>
    <TabTitle>Mobile App</TabTitle>
    <MediumText>
      You can download the Skylight Plus app on the Apple and Google Play stores.
    </MediumText>
    <MediumText>To set up your account for the first time, follow these steps:</MediumText>

    <SmallText>1. Enter your personal email address.</SmallText>
    <Image
      alt="Enter your Email"
      src="https://i.shgcdn.com/cc8c32ba-bacb-4558-a1f6-5bf7a935bfc2/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <SmallText>2. Type in your phone number and pick a password.</SmallText>
    <Image
      alt="Enter your Password"
      src="https://i.shgcdn.com/ad8f9bf8-8837-4ceb-9f09-af4465351f2d/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <SmallText>
      3. Select "Activate New Frame" if you're activating a new Skylight, or "Find Existing Frame"
      if you're connecting to a friend's Skylight.
    </SmallText>
    <Image
      alt="Activate your Frame"
      src="https://i.shgcdn.com/5c053b7f-5382-412f-a359-5e75ffd8ee6b/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <SmallText>
      * 4a. If you're connecting to someone else's Skylight, you will need to type in their Skylight
      email address.
    </SmallText>
    <Image
      alt="Connect to a Frame"
      src="https://i.shgcdn.com/ff73c7c8-31c5-4d8c-9090-6fe29553c620/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <SmallText>
      * 4b. If you are activating a new frame, type the 6-digit code into your Skylight Frame.
    </SmallText>
    <Image
      alt="Enter the Code"
      src="https://i.shgcdn.com/ef393593-4733-49fd-8d8d-002df7999a7a/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <SmallText>5. Set up your privacy settings.</SmallText>
    <Image
      alt="Set up Privacy"
      src="https://i.shgcdn.com/5851d3bc-0e31-48b9-933a-a478aad2af2d/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>
      *6. If you choose your frame to be private, you can approve a list of friends and family who
      can send photos to your Skylight.
    </SmallText>
    <Image
      alt="Manage List of Senders"
      src="https://i.shgcdn.com/c6438410-881d-4e5e-9739-ec8ba67a8476/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>
      <strong> You are all set! </strong>You should now be able to see all your Skylight Frames in
      the app.
    </SmallText>
    <Image
      alt="List of Frames"
      src="https://i.shgcdn.com/91ce2cc7-0380-4cef-a866-7b85da78941a/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <Hr />

    <MediumText>To send photos or videos from the app, follow these steps:</MediumText>
    <SmallText>
      1. Log in to the app and click on the name of the frame you wish to send a photo or video to.
    </SmallText>

    <SmallText>2. Click the arrow icon on the top right corner.</SmallText>
    <Image
      alt="Send Icon"
      src="https://i.shgcdn.com/790423f3-0379-4f27-b4e6-18339acdfe14/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <SmallText>
      3. Pick a photo or video from your photo gallery or use your camera to take a new one!
    </SmallText>
    <Hr />

    <MediumText>To download a photo or video from the cloud portal, follow these steps:</MediumText>
    <SmallText>
      1. Log in to the app and click on the name of the frame you wish to download a photo or video
      from.
    </SmallText>
    <SmallText>2. Click on the photo or video you want to download.</SmallText>
    <SmallText>3. Click "Download Video" or "Download Photo".</SmallText>

    <Hr />

    <MediumText>
      To share a photo or video directly from the app to your social media, follow these steps:
    </MediumText>
  </>
)

export default MobileApp
