import React from 'react'

import { Hr, MediumText, SmallText } from 'src/utils/styles/global-styles'
import { Image, TabTitle } from './styles'

const Videos = () => (
  <>
    <TabTitle>Videos</TabTitle>
    <MediumText>
      You can send videos to your Skylight from the cloud portal or mobile app.
    </MediumText>

    <Hr />

    <MediumText>To send videos from the app, follow these steps:</MediumText>
    <SmallText>
      1. Log in to the app and click on the name of the frame you wish to send a video to.
    </SmallText>
    <SmallText>2. Click the arrow icon on the top right corner.</SmallText>
    <Image src="https://i.shgcdn.com/790423f3-0379-4f27-b4e6-18339acdfe14/-/format/auto/-/preview/3000x3000/-/quality/lighter/" />

    <SmallText>3. Pick a video from your gallery or use your camera to take a new one!</SmallText>

    <Hr />

    <MediumText>To send photos or videos from the cloud portal, follow these steps:</MediumText>
    <SmallText>
      1. Log in to the portal and click on the name of the frame you wish to send a photo or video
      to.
    </SmallText>
    <SmallText>2. Click "Upload New Photo" on the top right corner.</SmallText>
    <SmallText>3. Choose a file from your computer and click "Upload Photo"</SmallText>
    <Image src="https://i.shgcdn.com/bcefa377-62b8-45b1-bdab-0f10e89ce29d/-/format/auto/-/preview/3000x3000/-/quality/lighter/" />
  </>
)

export default Videos
