import React from 'react'

import { Hr, MediumText, SmallText } from 'src/utils/styles/global-styles'
import { Image, TabTitle } from './styles'

const CloudPortal = () => (
  <>
    <TabTitle>Cloud Portal</TabTitle>
    <MediumText>
      You can access the Skylight cloud portal any time by going to{' '}
      <a href="http://app.ourskylight.com">app.ourskylight.com</a>
    </MediumText>
    <MediumText>To set up your account for the first time, follow these steps:</MediumText>
    <SmallText>1. Enter your personal email address.</SmallText>
    <Image
      alt="Enter your Email"
      src="https://i.shgcdn.com/694a498b-0b5f-40c1-ab5c-6c7f4e94d648/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>2. Type in your phone number and pick a password.</SmallText>
    <Image
      alt="Enter your Password"
      src="https://i.shgcdn.com/fc176360-4973-40ab-9681-8f15e7a1df65/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>3. Create your Skylight email address.</SmallText>
    <Image
      alt="Create a Skylight Email"
      src="https://i.shgcdn.com/c4f77849-13ce-4d30-9d97-52f74b9bf095/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>4. Activate your frame by typing the code into your Skylight Frame.</SmallText>
    <Image
      alt="Activate your Frame"
      src="https://i.shgcdn.com/d27ea13c-c461-4c74-9edc-e8e8908a6c85/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>5. Set up your privacy settings.</SmallText>
    <Image
      alt="Set up Privacy"
      src="https://i.shgcdn.com/efb1743d-8351-4646-8262-c2603841c2d8/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>
      *6. If you choose your frame to be private, you can approve a list of friends and family who
      can send photos to your Skylight.
    </SmallText>
    <Image
      alt="Manage list of senders"
      src="https://i.shgcdn.com/86479e1b-0a0b-4918-aa2a-2ced02dc1283/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />
    <SmallText>
      <strong> You are all set! </strong>You should now be able to see all your Skylight Frames in
      the portal.
    </SmallText>
    <Image
      alt="Your Frames"
      src="https://i.shgcdn.com/358466e8-8d11-4209-8f07-4ae3f9f6b97b/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <MediumText>To send photos or videos from the cloud portal, follow these steps:</MediumText>
    <SmallText>
      1. Log in to the portal and click on the name of the frame you wish to send a photo or video
      to.
    </SmallText>
    <SmallText>2. Click "Upload New Photo" on the top right corner.</SmallText>
    <SmallText>3. Choose a file from your computer and click "Upload Photo"</SmallText>
    <Image
      alt="Upload a Photo"
      src="https://i.shgcdn.com/bcefa377-62b8-45b1-bdab-0f10e89ce29d/-/format/auto/-/preview/3000x3000/-/quality/lighter/"
    />

    <Hr />

    <MediumText>To download a photo or video from the cloud portal, follow these steps:</MediumText>
    <SmallText>
      1. Log in to the portal and click on the name of the frame you wish to download a photo or
      video
    </SmallText>
    <SmallText>2. Click on the photo or video you want to download.</SmallText>
    <SmallText>3. Click "Download" on the top right corner.</SmallText>
  </>
)

export default CloudPortal
