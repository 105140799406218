import React from 'react'

import SkylightPlusGuide from 'src/components/SkylightPlusGuide'

import { ContentContainer } from 'src/utils/styles/global-styles'

import SEO from 'src/components/seo'

const HowToUseSkylightPlus = () => (
  <>
    <SEO
      title="How to Use Skylight Plus"
      description="Skylight Plus allows you to access our cloud portal and mobile app, where you can send photos and videos to Skylight, and even add captions! Skylight Plus can be used with all your Skylight Frames and by everyone in the family."
      path="/pages/how-to-use-skylight-plus/"
    />
    <ContentContainer>
      <SkylightPlusGuide />
    </ContentContainer>
  </>
)

export default HowToUseSkylightPlus
