import styled from 'styled-components'

import { breakpoints } from 'src/utils/styles/global-styles'

export const Image = styled.img`
  width: 80%;
  max-width: 400px;
`

export const Container = styled.div`
  max-width: 600px;
  margin: auto;
`

export const Tabs = styled.ul`
  margin: 0 !important;
  list-style: none !important;
  padding: 0 0 0 0;
  overflow: visible;
  display: flex;
`

export const Tab = styled.li`
  margin-bottom: 0;
  float: left;
  box-sizing: border-box;
  margin-right: 2px;
  position: relative;
  display: block;
  overflow: visible;
  background: ${(props) => props.theme.backgroundLight};
  border: 2px solid ${(props) => (props.active ? props.theme.forest : props.theme.gray)};
  border-bottom: 2px solid ${(props) => props.theme.backgroundLight};
`

export const TabButton = styled.button`
  padding: 10px 20px;
  color: ${(props) => (props.active ? props.theme.forest : props.theme.gray)};
  cursor: pointer;
  background: ${(props) => props.theme.backgroundLight};
  border: none;
  @media (max-width: ${breakpoints.m}px) {
    padding: 10px 5px;
    font-size: 12px;
  }
`
export const TabTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 300;
`
